import * as React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import { Helmet } from "react-helmet";

const Index = ({ data, location }) => {
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMarkdownRemark.nodes;

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet>
        <title>
          Live in Brisbane - Your Complete Guide to Life in Brisbane.
        </title>
        <link rel="canonical" href="https://liveinbrisbane.com.au/" />
        <meta
          name="description"
          content="Discover the joys of living in Brisbane, Australia with our comprehensive guide. Get insights about the local culture, employment opportunities, points of interest, and more. Start planning your big move with Live in Brisbane today!"
        />

        <script type="application/ld+json">
          {`
            {
              "@context": "http://www.schema.org",
              "@type": "Organization",
              "name": "Live in Brisbane",
              "url": "https://www.liveinbrisbane.com.au/",
              "logo": "https://www.liveinbrisbane.com.au/static/4dcefb04f813c8b4dfc1c8dfe427f5e8/8acde/logo-crop.avif",
              "image": "https://www.liveinbrisbane.com.au/static/4dcefb04f813c8b4dfc1c8dfe427f5e8/8acde/logo-crop.avif",
              "description": ""
            }
        `}
        </script>
      </Helmet>

      <h1 class="home-intro">
        Welcome to <b>Live in Brisbane</b>
      </h1>
      <div className="home-intro-container">
        <p>
          Your one-stop source for everything you need to know about relocating
          to Brisbane.
        </p>
        <p>
          Moving, while intimidating, can also be an incredible adventure.
          Navigating the steps to a successful relocation doesn't have to be a
          cumbersome task.
        </p>
        <p>
          We are here to assist both individuals and families in making a smooth
          and well-informed transition to Brisbane.
        </p>
        <img
          fetchpirority="high"
          loading="eager"
          src="https://cdn.shopify.com/s/files/1/0507/6697/1031/files/brisbane-local-marketing-nHiPAtsZcCo-unsplash.jpg?v=1701156305"
          alt="Live in Brisbane banner"
        />
      </div>
      <div className="explore-container">
        <div
          className="blog-list-container grid grid-cols-2 gap-4 mt-8"
          style={{ listStyle: `none` }}
        >
          {posts.map((post) => {
            const title = post.frontmatter.title || post.fields.slug;            

            if (post.fields.slug.includes("zh")) {
              return;
            } else {
              return (
                <div className="blog-list" key={post.fields.slug}>
                  <article
                    className="post-list-item"
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    <header>
                      <h2>
                        <Link
                          className="text-lg font-bold no-underline text-black hover:underline"
                          to={post.fields.slug}
                          itemProp="url"
                        >
                          <img
                            className="max-w-sm mx-5 hover:opacity-80"
                            src={post.frontmatter.frontimage}
                          />
                          <span itemProp="headline">{title}</span>
                        </Link>
                      </h2>
                      <small>{post.frontmatter.date}</small>
                    </header>
                    <section>
                      <p
                        className="max-w-xl"
                        dangerouslySetInnerHTML={{
                          __html: post.frontmatter.description || post.excerpt,
                        }}
                        itemProp="description"
                      />
                    </section>
                  </article>
                </div>
              );
            }
          })}
        </div>
      </div>
    </Layout>
  );
};

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          frontimage
        }
      }
    }
  }
`;
